<template>
  <layout-modal 
    v-bind="attrs"
    v-on="listeners">
    <template #header>
      <h2 class="layout-modal__title">{{ title }}</h2>
      <p class="layout-modal__subtitle">{{ subtitle }}</p>
    </template>

    <div 
      class="modal-wallet-qr__qr" 
      v-if="qrSrc">
      <img 
        :alt="title"
        :src="qrSrc" 
      />
    </div>

    <!-- <forms-input
      icon-post-interactive
      icon-post="copy"
      @iconClick="onCopy"
      :value="qrAddress"
    /> -->
      
    <template #footer>
      <actions-button
        :appearance="$pepper.Appearance.SUBTLE"
        @click="back"
      >{{ $t('user-portal.action_close') }}</actions-button>
    </template>
  </layout-modal>
</template>

<script>
import Config from '@/config'
import LayoutModal from '@/components/layouts/modal.vue'

export default {
  name: 'ModalNft',

  components: {
    LayoutModal,
  },

  inject: [
    '$user',
    '$wallet',
  ],

  props: {
    
  },

  data() {
    return {
     
    }
  },

  computed: {
    attrs() {
      return {
        classes: {
          'modal-wallet-qr': true
        },
        visible: true,
      }
    },

    listeners() {
      return {
        close: this.back
      }
    },

    qrAddress() {
      return this.$basil.get(this.wallet, 'address') 
    },

    qrData() {
      return this.$basil.get(this.wallet, 'address') + '|' + this.$basil.get(this.wallet, 'secretType')
    },

    qrSrc() {
      return `${Config.transports.cp.api}qr?text=${encodeURIComponent(this.qrData)}&format=svg&size=256`
    },

    subtitle() {
      return this.$basil.get(this.wallet, 'address')
    },

    title() {
      return this.$t('user-portal.wallet_qr_code_title')
    },

    wallet() {
      return this.$wallet.wallet
    }
  },

  methods: {
    back() {
      this.$emit('close')
    },

    async onCopy() {
      if(navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(this.qrAddress)

          this.$notification({
            title: this.$t('user-portal.copy_to_clipboard'),
            type: 'success'
          })
        } catch(e) {
          this.$notification({
            title: this.$t('user-portal.copy_to_clipboard_failed'),
            type: 'error'
          })
        }
      } else {
        let tempInput = document.createElement('input')
        tempInput.setAttribute('style', 'opacity: 0; position: fixed; top: 0; z-index: -1')
        tempInput.value = this.qrAddress || null
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        ctx.done()
        this.$notification({
          title: this.$t('user-portal.copy_to_clipboard'),
          type: 'success'
        })
        document.body.removeChild(tempInput)
      }
    },
  },
}
</script>