<template>
 <main>
    <div class="container">
      <!-- Header -->
      <header class="user__header">
        <navigations-breadcrumb 
          class="view-user-wallet__breadcrumb"
          :children="breadcrumbs" 
        />

        <div class="view-user-wallet__descr">
          <actions-button 
            :appearance="$pepper.Appearance.SUBTLE"
            class="view-user-wallet__address"
            :class="{ '--100': descr100 }"
            icon-post="copy"
            ref="descr"
            :size="$pepper.Size.S"
            @click="onCopy"
            v-if="!$basil.isNil($basil.get(wallet, 'address'))"
          >{{ $t('user-portal.user_wallet_address', { address: $basil.get(wallet, 'address') }) }}</actions-button>
        </div>
      </header>
      
      <div class="view-user-wallet__section">
        <div class="view-user-wallet__header">
          <div class="view-user-wallet__texts">
            <h2 class="user__subtitle">{{ $t('user-portal.user_wallet_nfts_title') }}</h2>
            <p class="user__subdescription">{{ $t('user-portal.user_wallet_nfts_descr') }}</p>
          </div>

          <div class="row">
            <p 
              class="view-user-wallet__pendings"
              v-if="pendingsCount && pendingsCount > 0"
            >{{ $t('user-portal.user_wallet_in_pendings', { count: pendingsCount }) }}</p>

            <actions-button
              class="view-user-wallet__aheader"
              icon-pre="redo"
              :disabled="loading"
              @click="onRefresh"
            />

            <actions-button
              class="view-user-wallet__aheader --qr"
              :disabled="loading"
              :title="$t('user-portal.wallet_qr_code_title')"
              @click="onQrClick"
            ><qr-icon /></actions-button>

            <actions-button
              class="view-user-wallet__aheader"
              :href="{ name: 'sayl-user-portal.transfers' }"
              v-if="transfers && transfers.length > 0"
            >{{ $t('user-portal.go_to_claim') }}</actions-button>
          </div>
        </div>

        <div 
          class="view-user-wallet__loader" 
          v-if="loading">
          <ui-loader />
        </div>

        <!-- No NFTS -->
        <data-empty
          class="view-user-wallet__empty" 
          :description="$t('user-portal.user_wallet_no_nfts_description')"
          :title="$t('user-portal.user_wallet_no_nfts')"
          v-if="!loading && nfts.length === 0"
        />

        <!-- NFTS list -->
        <div 
          class="view-user-wallet__nfts" 
          v-if="!loading && nfts.length > 0">
          <ui-nft-card
            :key="getNftKey(nft)"
            :value="nft"
            @click="() => onNftClick(nft)"
            v-for="nft in nfts"
          />
        </div>
      </div>
    </div>

    <wallet-qr 
      @close="onQrClose"
      v-if="showQrWallet"
    />

    <router-view v-if="!loading"/>
  </main>
</template>

<script>
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'
import UiNftCard from '@/components/ui/nft-card.vue'
import QrIcon from '@/components/ui/qr-icon.vue'
import WalletQr from './wallet-qr.vue'

export default {
  name: 'UserWallet',

  components: {
    NavigationsBreadcrumb,
    QrIcon,
    UiNftCard,
    WalletQr,
  },

  inject: [
    '$nft',
    '$user',
    '$wallet',
  ],

  data() {
    return {
      breadcrumbs: [
        { label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' }},
        { label:  this.$t('user-portal.user_wallet_title'), href: '#' }
      ],
      debounce: null,
      descr100: false,

      loading: true,
      showQrWallet: false,
    }
  },

  computed: {
    nfts() {
      return this.$wallet.nfts
    },

    pendings() {
      return this.$wallet.pendings || []
    },

    pendingsCount() {
      return this.pendings.length
    },

    transfers() {
      return this.$wallet.transfers || []
    },

    user() {
      return this.$user.user
    },

    wallet() {
      return this.$wallet.wallet
    }
  },

  methods: {
    getNftKey(nft) {
      return this.$basil.get(nft, 'contract.address') + '_' + nft.id
    },

    onNftClick(nft) {
      this.$router.push({ 
        name: 'sayl-user-portal.wallet.nft', 
        params: { 
          contract: this.$basil.get(nft, 'contract.address', null),
          id: nft.id 
        }
      }).catch(() => {})
    },

    async onCopy() {
      let address = this.$basil.get(this.wallet, 'address')

      if(navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(address)

          this.$notification({
            title: this.$t('user-portal.copy_to_clipboard'),
            type: 'success'
          })
        } catch(e) {
          this.$notification({
            title: this.$t('user-portal.copy_to_clipboard_failed'),
            type: 'error'
          })
        }
      } else {
        let tempInput = document.createElement('input')
        tempInput.setAttribute('style', 'opacity: 0; position: fixed; top: 0; z-index: -1')
        tempInput.value = address || null
        document.body.appendChild(tempInput)
        tempInput.select()
        document.execCommand('copy')
        ctx.done()
        this.$notification({
          title: this.$t('user-portal.copy_to_clipboard'),
          type: 'success'
        })
        document.body.removeChild(tempInput)
      }
    },
  
    async onRefresh() {
      try {
        this.loading = true
        await this.$wallet.view({})
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    onQrClick() {
      this.showQrWallet = true
    },

    onQrClose() {
      this.showQrWallet = false
    }
  },

  mounted() {
    if(!this.user || !this.user.hasWallet) {
      this.$router.push({ name: 'sayl-user-portal.home' }).catch(() => {})
      return
    }

    this.onRefresh()
  },
}
</script>